import SmoothScroll from 'smooth-scroll';
import $slick from 'slick-carousel';
import $modaal from 'modaal';

var MAIN = MAIN || {};

/* ================================================== *
/*
/*    function
/*
/* ================================================== */

MAIN.fn = {};

/* ------------------------------------------------- *
 *        detectDevice1
 * ------------------------------------------------- */

MAIN.fn.deviceCheck1 = {
  emt: {
    ua      : '',
    ver     : '',
    ie      : '',
    browser : '',
    device  : '',
  },
  init: function() {
    var emt = this.emt;
    emt.ua = navigator.userAgent.toLowerCase();
    emt.ver = navigator.appVersion.toLowerCase();

    var detectIE = function(){
      if (emt.ver.indexOf('msie 6.') >= 0) {
        emt.ie = 'ie6';
      }
      else if (emt.ver.indexOf('msie 7.') >= 0) {
        emt.ie = 'ie7';
      }
      else if (emt.ver.indexOf('msie 8.') >= 0) {
        emt.ie = 'ie8';
      }
      else if (emt.ver.indexOf('msie 9.') >= 0) {
        emt.ie = 'ie9';
      }
      else if (emt.ver.indexOf('msie 10.') >= 0) {
        emt.ie = 'ie10';
      }
      else {
        emt.ie = 'other';
      }
    }();

    var detectBrowser = function(){
      if (emt.ua.indexOf('chrome') >= 0) {
        emt.browser = 'chrome';
      }
      else if (emt.ua.indexOf('safari') >= 0) {
        emt.browser = 'safari';
      }
      else if (emt.ua.indexOf('opera') >= 0) {
        emt.browser = 'opera';
      }
      else if (emt.ua.indexOf('firefox') >= 0) {
        emt.browser = 'firefox';
      }
      else if (emt.ua.indexOf('trident/7') >= 0) {
        emt.browser = 'ie11';
      }
      else if (emt.ua.indexOf("msie") >= 0) {
        emt.browser =  detectIE();
      }
    }();

    var detectDevice = function(){
      if ((emt.ua.indexOf('android') > 0 && emt.ua.indexOf('mobile') === -1 ) || emt.ua.indexOf('ipad') > 0) {
        emt.device = 'tb';
      }
      else if ((emt.ua.indexOf('iphone') > 0 && emt.ua.indexOf('ipad') === -1 ) || emt.ua.indexOf('ipod') > 0 || (emt.ua.indexOf('android') > 0 && emt.ua.indexOf('mobile') > 0)) {
        emt.device = 'sp';
      }
      else {
        emt.device = 'pc';
      }
    }();

    if(emt.browser.indexOf('ie') >= 0){
      $('body').addClass('is-ie');
    }
    $('body').addClass('is-'+emt.browser).addClass('is-'+emt.device);
  }
}

/* ------------------------------------------------- *
/*        nav1
/* ------------------------------------------------- */

MAIN.fn.nav1 = {
  emt: {
    target : '.js-header1',
    btn1   : '.js-globalNav1__btnToggle1',
    body1  : '.js-globalNav1__navlist1',
    spd1   : '250',
  },
  init: function () {
    var emt = this.emt;
    $(emt.btn1).on('click', function(){
      if(!$(emt.body1).hasClass('-active')) {
        $(emt.body1).slideDown(emt.spd1).addClass('-active');
        $(emt.btn1).addClass('-active');
      } else {
        $(emt.body1).slideUp(emt.spd1).removeClass('-active');
        $(emt.btn1).removeClass('-active');
      }
    })
  }
}

/* ------------------------------------------------- *
/*        modal1
/* ------------------------------------------------- */

MAIN.fn.modal1 = {
  emt: {
    target: '.js-modal'
  },
  init: function () {
    var emt = this.emt;
    $(emt.target).modaal();
  }
};

/* ------------------------------------------------- *
/*        slick1
/* ------------------------------------------------- */

MAIN.fn.slick1 = {
  emt: {
    target : '.js-slick1',
  },
  init: function () {
    var emt = this.emt;
    $(emt.target).slick({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 5000,
    });
  }
}

/* ------------------------------------------------- *
/*        slick2
/* ------------------------------------------------- */

MAIN.fn.slick2 = {
  emt: {
    target : '.js-slick2',
  },
  init: function () {
    var emt = this.emt;
    $(emt.target).slick({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
    });
  }
}

/* ------------------------------------------------- *
/*        contact1
/* ------------------------------------------------- */

MAIN.fn.contact1 = {
  emt: {
    target : '.js-header1__contact1',
    spd   : '250',
  },
  init: function() {
    var emt = this.emt;
    var doch = $(document).innerHeight();
    var winh = $(window).innerHeight() + 100;
    var bottom = doch - winh;
    if (bottom <= $(window).scrollTop()) {
      $(emt.target).addClass('-hide');
    } else {
      $(emt.target).removeClass('-hide');
    }
  }
}

/* ------------------------------------------------- *
/*        pagetop1
/* ------------------------------------------------- */

MAIN.fn.pagetop1 = {
  emt: {
    target : '.js-btnPagetop1',
    spd   : '250',
  },
  init: function() {
    var emt = this.emt;
    var doch = $(document).innerHeight();
    var winh = $(window).innerHeight() + 100;
    var bottom = doch - winh;
    if (bottom <= $(window).scrollTop()) {
      $(emt.target).addClass('-hide');
    } else {
      $(emt.target).removeClass('-hide');
    }
  }
}

/* ------------------------------------------------- *
/*        smooth-scroll1
/* ------------------------------------------------- */

MAIN.fn.smoothScroll1 = {
  init: function() {
    var scroll = new SmoothScroll('.js-smooth',{
      speed  : 500,
      easing : 'easeOutQuint'
    });
  }
}

/* ------------------------------------------------- *
/*        imageSlide1
/* ------------------------------------------------- */

MAIN.fn.imageSlide1 = {
  emt: {
    target : '.js-imageSlide1',
    mainImage1 : '.js-imageSlide1__mainImage1',
    thumb1 : '.js-imageSlide1__thumb1',
    spd   : '250',
  },
  init: function() {
    var emt = this.emt;
    $(emt.thumb1).find('li').on('click', function(){
      var index = $(this).index();
      index++;
      $(emt.mainImage1).find('li').fadeOut(emt.spd);
      $('li:nth-child('+ index +')',emt.mainImage1).fadeIn(emt.spd);
    });
  }
}

/* ------------------------------------------------- *
/*        more
/* ------------------------------------------------- */

MAIN.fn.more1 = {
  emt: {
    target : '.js-accordion1__btnMore1',
    spd : 350,
  },
  init: function () {
    var emt = this.emt;
    $(emt.target).on('click', function(){
      if( $(this).hasClass('-active1') ){
        $(this).removeClass('-active1').parents('.js-accordion1').find('.-hide').slideUp(emt.spd);
      } else {
        $(this).addClass('-active1').parents('.js-accordion1').find('.-hide').slideDown(emt.spd);
        $(MAIN.fn.slick2.emt.target).slick('setPosition');
      }
    });
  }
}

/* ================================================== *
/*
/*    run
/*
/* ================================================== */

$(document).ready(function () {
  MAIN.fn.nav1.init();
  MAIN.fn.slick1.init();
  MAIN.fn.slick2.init();
  MAIN.fn.modal1.init();
  MAIN.fn.imageSlide1.init();
  MAIN.fn.smoothScroll1.init();
  MAIN.fn.more1.init();
});

$(window).scroll(function(){
  MAIN.fn.contact1.init();
  MAIN.fn.pagetop1.init();
});

